<template>
    <div class="tooltipHolder" v-click-outside="hideHolder">
        <div class="text">
            <div class="textHolder" @mouseenter="showHolder" @mouseleave="hideHolder">
                <slot name="text"></slot>
            </div>
        </div>
        <div class="holder" :class="{ 'active' : shown }">
            <div class="triangle"></div>
            <div class="tooltip">
                <slot name="content"></slot>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                shown: false
            }
        },
        methods: {
            showHolder() {
                this.shown = true
            },
            hideHolder() {
                this.shown = false
            }
        },
    }
</script>

<style lang="scss" scoped>

.text {
    display: flex;
    justify-content: flex-start;
}

.textHolder {
    cursor: pointer;
}

.tooltipHolder {
    position: relative;
    z-index: 10;
    &:hover {
        z-index: 11;
    }
    .holder {
        padding-bottom: 20px;
        position: absolute;
        left: 0;
        bottom: 100%;
        opacity: 0;
        pointer-events: none;
        transform: translate(0, -10px);
        transition: ease 0.3s;
        .triangle {
            position: absolute;
            left: 14px;
            bottom: 6px;
            width: 14px;
            height: 14px;
            transform: translate(0, -50%) rotate(45deg);
            background: $bgSecondary;
            border-bottom: 1px solid $borderColor;
            border-right: 1px solid $borderColor;
            border-radius: 0 0 0 2px;
            z-index: 2;
        }
        &.active {
            opacity: 1;
            pointer-events: auto;
            transform: translate(0, 0);
            z-index: 999;
        }
    }

    .tooltip {
        background: $bgSecondary;
        padding: 20px;
        border: 1px solid $borderColor;
        border-radius: 3px;
        position: relative;
        width: 320px;
        display: grid;
        row-gap: 10px;
        z-index: 1;
        box-shadow: $boxShadow;
        font-size: 12px;
        .line {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .name {
                font-size: 12px;
            }
            .value {
                font-weight: 600;
            }
        }
    }
    // &:hover {
    //     .holder {
    //         opacity: 1;
    //         pointer-events: auto;
    //         transform: translate(0, 0);
    //         z-index: 999;
    //     }
    // }
}


</style>