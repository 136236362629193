<template>
    <div class="load">
        <div class="col">#{{ load.loadID }}</div>
        <div class="col textNoWrap">{{ pickup }}</div>

        <div class="col carrier textNoWrap" v-if="load.carrierDetails && load.carrierDetails.name">{{ load.carrierDetails.name }}</div>
        <div v-else></div>

        <div class="col">{{ load.origin }}</div>
        <div class="col">{{ load.destination }}</div>
        <div class="col">${{ priceConverter(load.rate) }}</div>

        <div class="col">
            <div class="brokerDetails" :class="{'active' : showContactInfo}" v-click-outside="hideContactInfo">
                <div class="_name textNoWrap" @click="showContactInfo = true;">{{ load.broker }}</div>
                <div class="contactGroup" v-if="showContactInfo">
                    <div class="_title">Contact details</div>
                    <div class="cont">
                        <div class="row">
                            <div class="name">Name</div>
                            <div class="value">{{ load.contactName }}</div>
                        </div>
                        <div class="row" v-if="load.contactPhone">
                            <div class="name">Phone</div>
                            <div class="value">
                                {{ load.contactPhone }}
                                <a :href="`tel:${contact}`" class="default small round reverse btn" v-if="contact">Call</a>
                            </div>
                        </div>
                        <div class="row" v-if="emailAddress && emailAddress.length > 0">
                            <div class="name">E-mail</div>
                            <div class="value">
                                <div class="email textNoWrap">{{ emailAddress[0] }}</div>
                                <a href="" class="default small round reverse btn" @click.prevent="copyToClipboard(emailAddress[0])">Copy</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col"><div class="loadWeight"><span>{{ loadWeight }}</span><strong>klbs</strong></div></div>

        <div class="col">
            <ListPicker :key="load.status" 
            :options="loadStatus"
            @select="updateLoadStatus" 
            :selected="load.status"
            :disabled="!isPermittedToEdit"/>
        </div>

        <div class="col">
            <div class="dispatcher textNoWrap">
                <span>
                    <router-link :to="`/app/profile/${dispatcher.username}`" v-if="dispatcher.username">{{ dispatcher.name }}</router-link>
                    <a href="" @click.prevent v-else>{{ dispatcher.name }}</a>
                </span>
                &nbsp;
                <span v-if="load.sharingWith">
                    <span>with</span>&nbsp;
                    <span>
                        <router-link :to="`/app/profile/${sharingWith.username}`" v-if="sharingWith.username">{{ sharingWith.name }}</router-link>
                        <a href="" @click.prevent v-else>{{ sharingWith.name }}</a>
                    </span>
                </span>
            </div>
        </div>

        <div class="col textNoWrap">{{ load.displayDriverName }}</div>


        <div class="col"><span style="cursor: pointer;" v-if="load.notes && load.notes.length >= 1"
            v-tooltip="{ text: load.notes, theme: {
                                placement: 'left'
                            }
                        }"><i class="fas fa-sticky-note"></i></span></div>

        <div class="col pod"><span v-if="load.PODs && load.PODs.length > 0" v-tooltip="'Available POD'"><i class="fa-solid fa-file-circle-check"></i></span></div>

        <div class="actionBtn" :class="{ 'active' : showOptions }" v-click-outside="hideOptions">
            <button @click="switchOptions"><i class="fas fa-ellipsis-v"></i></button>
            <div class="menu" v-if="showOptions">
                <a href="" @click.prevent="$emit('view', load)">View</a>
                <a href="" @click.prevent="duplicateLoad">Duplicate</a>
            </div>
        </div>

    </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';

import ListPicker from '../ListPicker.vue'
import SimpleToolTip from '../SimpleToolTip.vue'

    export default {
        props: ['load'],
        components: {
            ListPicker,
            SimpleToolTip
        },
        data() {
            return {
                showOptions: false,
                showContactInfo: false
            }
        },
        methods: {
            duplicateLoad() {
                let loadCopy = JSON.parse(JSON.stringify(this.load));
                
                let load = {
                    carrier: loadCopy.carrier,
                    broker: loadCopy.broker,
                    stops: loadCopy.stops || [],
                    contactName: loadCopy.contactName,
                    contactEmail: loadCopy.contactEmail,
                    contactPhone: loadCopy.contactPhone,
                    driver: loadCopy.driver,
                    length: loadCopy.length,
                    weight: loadCopy.weight,
                    rate: loadCopy.rate
                }

                this.$store.state.loadManager.addLoad = true;
                this.$store.state.loadManager.addLoadDetails = load;
            },
            switchOptions() {
                this.showOptions = !this.showOptions;
            },
            hideOptions() {
                this.showOptions = false;
            },
            hideContactInfo() {
                this.showContactInfo = false;
            },
            updateLoadStatus(key) {
                let prevStatus = this.load.status;
                this.load.status = key;
                this.ajax('updateLoadStatus', {
                    method: 'POST',
                    url: `/dispatch/loadStatus/${this.load._id}/${key}`
                }, (err, body) => {
                    if(err) {
                        alert(body.message || 'Something went wrong. Please, try again!');
                        this.load.status = prevStatus;
                        return;
                    }
                });
            },
            copyToClipboard(val) {
                if (!navigator.clipboard) return;
                navigator.clipboard.writeText(val).then(function() {
                    console.log('Async: Copying to clipboard was successful!');
                }, function(err) {
                    console.error('Async: Could not copy text: ', err);
                });
            }
        },
        computed: {
            ...mapGetters(['carriers', 'users', 'loadStatus']),
            loadWeight() {
                if(!this.load.weight) return 0;
                let weight = 0;
                try {
                    weight = (Number(this.load.weight) / 1000).toFixed(0);
                    if(isNaN(weight)) {
                        weight = 0; 
                    }
                } catch(e) {

                }
                return weight;
            },
            isPermittedToEdit() {
                if(this.user._id == this.load.dispatcher && this.isUserPermitted("loads", "edit") || this.isUserPermitted("loads", "editAll")) return true;
                return false;
            },
            pickup() {
                return moment(this.load.date, 'MM/DD/YYYY').format("ddd, DD MMM");
            },
            carrier() {
                for(const item of this.carriers) {
                    if(item._id === this.load.carrier) return item;
                }
                return null;
            },
            dispatcher() {
                if(!this.load.dispatcherDetails) return { _id: null, username: null, name: 'Unknown' };
                if(!this.load.dispatcherDetails[0]) return { _id: null, username: null, name: 'Unknown' };
                return this.load.dispatcherDetails[0];
            },
            sharingWith() {
                if(!this.load.sharingWithDetails) return { _id: null, username: null, name: 'Unknown' };
                if(!this.load.sharingWithDetails[0]) return { _id: null, username: null, name: 'Unknown' };
                return this.load.sharingWithDetails[0];
            },
            parseContactString() {
                if(!this.load.contactPhone) return null;

                const regexp = new RegExp("\\+?\\(?\\d*\\)? ?\\(?\\d+\\)?\\d*([\\s./-]?\\d{2,})+","g");
                let contactList = [...this.load.contactPhone.matchAll(regexp)];

                return contactList;
            },
            contact() {
                return  this.parseContactString && this.parseContactString.length > 0 ? this.parseContactString[0][0] : null;
            },
            emailAddress() {
                return (this.load.contactEmail) ? this.load.contactEmail.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi) : null;
            },
        }
    }
</script>

<style lang="scss" scoped>

.brokerDetails {
    width: 100%;
    position: relative;
    z-index: 1;
    &.active {
        z-index: 999;
    }
    ._name {
        color: $buttonColor;
        cursor: pointer;
    }
    .contactGroup {
        position: absolute;
        bottom: 100%;
        left: 50%;
        transform: translate(-50%, -20px);
        width: 350px;
        background: $bgSecondary;
        border-radius: $borderRadius;
        box-shadow: $boxShadow;
        border: 1px solid $borderColor;
        ._title {
            border-bottom: 1px solid $borderColor;
            padding: 20px;
            font-weight: bold;
            text-transform: uppercase;
            font-size: 14px;
        }
        .cont {
            .row {
                height: 50px;
                padding: 0 10px 0 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .name {
                    font-weight: bold;
                }
                .value {
                    display: grid;
                    column-gap: 10px;
                    grid-template-columns: auto auto;
                    align-items: center;
                    .email {
                        max-width: 150px;
                    }
                    .btn {
                        height: 30px;
                        font-size: 12px;
                        padding: 0 10px;
                    }
                }
            }
        }
    }
}


.col {
    height: 100%;
    display: flex;
    align-items: center;
    &.carrier {
        text-overflow: ellipsis;
        overflow: hidden; 
        white-space: nowrap;
    }
}


.dispatcher {
    display: flex;
    align-items: center;
    .link {
        color: $buttonColor;
    }
}

.loadWeight {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 5px;
}

.pod {
    font-size: 12px;
    color: $buttonColor;
}
</style>